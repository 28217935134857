<template>
  <div class="layout-nav">
    <nav
      class="navbar navbar-expand-sm navbar-light shadow-sm bg-light p-0 position-absolute w-100"
      style="z-index: 10"
    >
      <a class="navbar-brand py-3 px-4" href="/">
        <img src="@/assets/logo.png" class="nav-logo" />
      </a>
      <button
        class="btn btn-link text-light px-4 ml-auto d-sm-none"
        @click="toggleSound()"
        v-if="!['User Management', 'AISC'].includes($route.name)"
      >
        <i :class="`fas fa-volume-${muteSound ? 'mute' : 'up'}`"></i>
      </button>
      <button
        class="navbar-toggler border-0"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <!-- <li class="nav-item">
            <router-link
              class="nav-link"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              to="/cameras"
              >Cameras</router-link
            >
          </li> -->
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/drone"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              >Drone</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              to="/detections"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              >Detections</router-link
            >
          </li>
          <!-- <li class="nav-item">
            <router-link
              class="nav-link"
              to="/aisc"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              >AISC</router-link
            >
          </li> -->
        </ul>
        <ul
          class="navbar-nav ml-auto pr-md-3 d-none d-sm-block"
          v-if="!['User Management', 'AISC'].includes($route.name)"
        >
          <li class="nav-item text-right">
            <button class="btn btn-link text-light px-4" @click="toggleSound()">
              <i :class="`fas fa-volume-${muteSound ? 'mute' : 'up'}`"></i>
            </button>
          </li>
        </ul>
        <div class="dropdown">
          <button
            class="btn btn-link text-light dropdown-toggle d-flex align-items-center"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="far fa-user-circle fa-2x mr-2"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right mr-3"
            aria-labelledby="dropdownMenuButton"
          >
            <p class="dropdown-item mb-0 text-truncate">{{ fullname }}</p>
            <p class="dropdown-item mb-0 text-muted text-truncate">
              {{ userEmail }}
            </p>
            <template v-if="role == 'admin'">
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="goTo('User Management')"
              >
                <i class="fas fa-users mr-2"></i>
                User Management
              </a>
            </template>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" @click.prevent="logout()">
              <i class="fas fa-sign-out-alt mr-2"></i>
              Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
    <slot />
  </div>
</template>
<script>
export default {
  data() {
    return {
      userEmail: null,
      fullname: null,
      role: null,
      muteSound: true,
    };
  },
  methods: {
    goTo(name) {
      if (this.$route.name != this.$router.name) {
        this.$router.push({
          name: name,
        });
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push({
        name: "Auth",
      });
    },
    toggleSound() {
      this.muteSound = !this.muteSound;
      this.$emit("toggleSound", this.muteSound);
    },
  },
  mounted() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));

      this.userEmail = user.email;
      this.fullname = user.fullname;
      this.role = user.role;
    }
  },
};
</script>