<template>
  <div id="app">
    <component :is="layout" @toggleSound="toggleSound">
      <router-view
        :latestDetection="latestDetection"
        :latestViolation="latestViolation"
      />
    </component>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Sound from "@/assets/sounds/detection.mp3";

const defaultLayout = "default";

export default {
  data() {
    return {
      latestDetection: null,
      latestViolation: null,
      notificationSound: new Audio(Sound),
      muteSound: true,
    };
  },
  computed: {
    layout() {
      let layout = `${this.$route.meta.layout || defaultLayout}-layout`;

      return layout;
    },
  },
  methods: {
    toggleSound(data) {
      this.muteSound = data;
    },
  },
  sockets: {
    connect: function () {
      console.log("Socket connected");
    },
    disconnect() {
      console.warn("Socket disconnected");
    },
    personDetected: function (data) {
      if (
        this.$route.name != "Auth" &&
        this.$route.name != "AISC" &&
        this.$route.name != "User Management"
      ) {
        this.latestDetection = data;

        if (!this.muteSound) {
          this.notificationSound.play();
        }

        Swal.fire({
          toast: true,
          position: "top",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "X",
          buttonsStyling: false,
          customClass: {
            cancelButton: "btn btn-link btn-sm",
            container: "toast-offset",
            title: "text-left",
          },
          timer: 10000,
          timerProgressBar: true,
          icon: "success",
          title: `New detection at ${data.camera.name}`,
          html: `<a class='btn btn-dark btn-sm' href='./#/detections?cctv=${data.camera.id}'>View</a>`,
        });
      }
    },
    violation: function (data) {
      if (this.$route.name == "AISC") {
        this.latestViolation = data;

        Swal.fire({
          toast: true,
          position: "top",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "X",
          buttonsStyling: false,
          customClass: {
            cancelButton: "btn btn-link btn-sm",
            container: "toast-offset",
            title: "text-left",
          },
          timer: 10000,
          timerProgressBar: true,
          icon: "warning",
          title: `AISC violation detected!`,
        });
      }
    },
  },
};
</script>